<template>
  <!-- Dialog Add Marketing Budget -->
  <ib-dialog
    id="dialog-story-mode-add-marketing-budget"
    empty-header
    :visible="dialogAddMarketingElementVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addMarketingCost') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addMarketingCost') }}
          </h2>
          <!-- /Title -->
        </template>

      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.marketingBudget.addDialog.guide.title')"
        :text="$t('pages.businessGuide.marketingBudget.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy
        type="marketing-costs"
        class="ai-buddy-margin-bottom d-block d-xl-none"
        @suggestion="form.name = $event"
      />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="30">

            <!-- Left Side -->
            <el-col :md="12" :xl="7">

              <!-- Form Wrapper -->
              <div class="marketing-form-wrapper">

                <!-- Form Inner -->
                <div class="marketing-form-inner-top">

                  <!-- Form -->
                  <el-form>

                    <!-- Name -->
                    <ib-input
                      v-model="form.name"
                      :label="$t('fields.name')"
                    />
                    <!-- /Name -->

                    <!-- Marketing Category -->
                    <el-form-item
                      id="mb-dropdown"
                      class="input-inside-label" :label="$t('fields.category')"
                      :class="{'focused' : focus.category || form.category !== ''}"
                    >

                      <!-- Marketing Category Select -->
                      <ib-select
                        ref="groupSelect"
                        v-model="form.marketingElementCategoryLabel"
                        :popper-append-to-body="false"
                        popper-class="promo-dropdown"
                        placeholder=" "
                        @change="selectOption"
                        @focus="toggleFocus('category')"
                        @blur="toggleFocus('category')"
                      >

                        <!-- Marketing Category Groups -->
                        <div v-for="group in marketingCategories" :key="group.value" class="group-wrapper">

                          <!-- Marketing Category Group -->
                          <div
                            class="group-item-wrapper"
                            :class="{
                              'selected' : group.value === form.category,
                              'disabled': true
                            }"
                          >
                            <!--                            @click="selectGroup(group)"-->

                            <!-- Startup Cost Group Image -->
                            <div class="round-image-wrapper">
                              <img class="marketing-budget-image" :src="group.imgPath" svg-inline>
                            </div>
                            <!-- /Startup Cost Group Image -->

                            <!-- Marketing Category Group Label -->
                            <span>{{ group.label }}</span>
                            <!-- /Marketing Category Group Label -->

                          </div>
                          <!-- /Marketing Category Group -->

                          <!-- Marketing Category Group Items -->
                          <ib-option
                            v-for="item in group.children"
                            :key="item.value"
                            class="w-arrows"
                            :label="item.label"
                            :value="item.value"
                          />
                          <!--                            :disabled="isSubcategoryDisabled(group.value)"-->
                          <!-- /Marketing Category Group Items -->

                        </div>
                        <!-- /Marketing Category Groups -->

                      </ib-select>
                      <!-- /Marketing Category Select -->

                    </el-form-item>
                    <!-- /Marketing Category -->

                  </el-form>
                  <!-- /Form -->

                  <!-- Payment Frequency -->
                  <form-switcher
                    v-model="form.amountIntervalType"
                    :options="[{
                      title: $t('oneTime'),
                      value: 'one_time'
                    },{
                      title: $t('constant'),
                      value: 'constant'
                    },{
                      title: $t('percentOfRevenue'),
                      value: 'revenue'
                    }]"
                  />
                  <!-- /Payment Frequency -->

                  <!-- Amount -->
                  <el-form>

                    <!-- Amount One Time & Constant  -->
                    <el-form-item
                      v-if="form.amountIntervalType !== 'revenue'"
                      class="currency-related-form-item"
                      :label="form.amountIntervalType === 'constant' ? $t('fields.monthlyAmount') : $t('fields.amount')"
                    >
                      <span class="currency-symbol">{{ currencySymbol }}</span>
                      <div class="currency-related-input amount el-input">
                        <currency-input
                          v-model="form.amount"
                          class="el-input__inner"
                          :distraction-free="false"
                          :currency="null"
                          :value-range="{min: 0, max: 999999999}"
                          :precision="{min: 0, max: 2}"
                          locale="en-US"
                        />
                      </div>
                    </el-form-item>
                    <!-- /Amount One Time & Constant  -->

                    <!-- Amount Revenue -->
                    <el-form-item
                      v-if="form.amountIntervalType === 'revenue'"
                      class="input-inside-label revenue-stream"
                      :label="$t('pages.businessGuide.marketingBudget.addDialog.fields.selectRevenueStream')"
                      :class="{'focused' : form.revenueStreamId !== ''}"
                    >
                      <ib-select
                        v-model="form.revenueStreamId"
                        :popper-append-to-body="false"
                        popper-class="promo-dropdown"
                        placeholder=" "
                      >
                        <ib-option
                          v-for="revenueStream in revenueStreams"
                          :key="revenueStream.id"
                          :label="revenueStream.name"
                          :value="revenueStream.id"
                          :class="{ 'selected': revenueStream.id === form.revenueStreamId }"
                        />
                      </ib-select>
                    </el-form-item>
                    <!-- /Amount Revenue -->

                    <!-- Revenue Percentage -->
                    <el-form-item
                      v-if="form.amountIntervalType === 'revenue'"
                      class="currency-related-form-item"
                      :label="$t('pages.businessGuide.marketingBudget.addDialog.fields.percentage')"
                    >
                      <div class="currency-related-input percentage el-input">
                        <currency-input
                          v-model="form.revenueStreamPercentage"
                          class="el-input__inner"
                          :distraction-free="false"
                          :currency="null"
                          :value-range="{min: 0, max: 100}"
                          :precision="{min: 0, max: 2}"
                          locale="en-US"
                        />
                      </div>
                      <span class="currency-symbol">%</span>
                    </el-form-item>
                    <!-- /Revenue Percentage -->

                  </el-form>
                  <!-- /Amount -->

                </div>
                <!-- /Form Inner -->

                <!-- Marketing Form Inner Bottom -->
                <div class="marketing-form-inner-bottom">

                  <!-- Form -->
                  <el-form>

                    <!-- Constant -->
                    <div v-if="form.amountIntervalType === 'constant'" class="group-product switches">

                      <!-- Budget Change -->
                      <el-form-item
                        class="input-inside-label" :label="$t('pages.businessGuide.marketingBudget.addDialog.fields.budgetChange')"
                        :class="{'focused': focus.budgetChangeAmount}"
                      >
                        <div class="input-with-select el-input">
                          <currency-input
                            v-model="form.budgetChangeAmount"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: form.isBudgetChangePercentage === '%' ? 100 : form.budgetChangePrefix === '-' && form.budgetChangeAmount > form.amount ? form.amount : 999999999}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                            @focus="toggleFocus('budgetChangeAmount')"
                            @blur="toggleFocus('budgetChangeAmount')"
                          />
                        </div>

                        <ib-tooltip
                          popper-class="center"
                          :content="$t('tooltipPositiveNegative')"
                        >
                          <el-button :icon="signValue" @click="toggleValue" />
                        </ib-tooltip>
                      </el-form-item>
                      <!-- /Budget Change -->

                      <!-- Period -->
                      <el-form-item
                        class="input-inside-label" label=""
                        :class="{'focused' : form.budgetChangeIntervalType !== ''}"
                      >
                        <ib-select
                          v-model="form.budgetChangeIntervalType"
                          :popper-append-to-body="false"
                          popper-class="promo-dropdown"
                        >
                          <ib-option :label="$t('monthly')" value="monthly" />
                          <ib-option :label="$t('quarterly')" value="quarterly" />
                          <ib-option :label="$t('annually')" value="yearly" />
                        </ib-select>
                        <ib-tooltip
                          popper-class="center"
                          :content="$t('tooltipValuesPercentages')"
                        >
                          <el-button @click="togglePayment">
                            <i
                              class="el-icon"
                              :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                            >
                              {{ paymentOption === '$' ? currencySymbol : paymentOption }}
                            </i>
                          </el-button>
                        </ib-tooltip>
                      </el-form-item>
                      <!-- /Period -->

                    </div>
                    <!-- /Constant -->

                    <!-- Constant & Percentage Of Revenue -->
                    <div
                      :class="{'visible': form.amountIntervalType === 'constant' || form.amountIntervalType === 'revenue'}"
                    >
                      <div class="group date">
                        <!-- Starts -->
                        <add-marketing-budget-datepicker
                          v-if="form.amountIntervalType === 'revenue'"
                          v-model="form.revenueStarts"
                          :label="$t('starts')"
                          :disabled="true"
                          :picker-options="pickerOptions"
                          @blur="toggleFocus('starts')"
                          @focus="toggleFocus('starts')"
                        />
                        <add-marketing-budget-datepicker
                          v-if="form.amountIntervalType === 'constant'"
                          v-model="form.starts"
                          :label="$t('starts')"
                          :picker-options="pickerOptions"
                          @blur="toggleFocus('starts')"
                          @focus="toggleFocus('starts')"
                        />
                        <!-- /Starts -->

                        <!-- Ends -->
                        <add-marketing-budget-datepicker
                          v-if="form.amountIntervalType === 'revenue'"
                          v-model="form.revenueEnds"
                          :label="$t('ends')"
                          :disabled="true"
                          :picker-options="pickerOptions"
                          @blur="toggleFocus('ends')"
                          @focus="toggleFocus('ends')"
                        />
                        <add-marketing-budget-datepicker
                          v-if="form.amountIntervalType === 'constant'"
                          v-model="form.ends"
                          :label="$t('ends')"
                          :picker-options="pickerOptions"
                          @blur="toggleFocus('ends')"
                          @focus="toggleFocus('ends')"
                        />
                        <!-- /Ends -->
                      </div>
                    </div>
                    <!-- /Constant & Percentage Of Revenue -->

                    <!-- One Time - When Will It Occur -->
                    <el-form-item
                      v-if="form.amountIntervalType === 'one_time'" class="input-inside-label yellow"
                      :label="$t('fields.whenWillItOccur')" prop="date.value"
                      :class="{'focused': true}"
                    >
                      <el-date-picker
                        v-model="form.whenWillItOccur"
                        :clearable="false"
                        :editable="false"
                        :picker-options="pickerOptions"
                        :popper-append-to-body="false"
                        format="MMM, yyyy"
                        class="date-picker-monthly"
                        popper-class="date-picker-monthly-dropdown-modal"
                        type="month"
                        value-format="yyyy-MM-dd"
                        @blur="toggleFocus('whenWillItOccur')"
                        @focus="toggleFocus('whenWillItOccur')"
                      />
                    </el-form-item>
                    <!-- /One Time - When Will It Occur -->

                  </el-form>
                  <!-- /Form -->

                  <!-- Advanced Settings -->
                  <!-- <el-button class="advanced">-->
                  <!--   Advanced Settings <sup>SOON</sup>-->
                  <!-- </el-button>-->
                  <!-- /Advanced Settings -->

                </div>
                <!-- /Marketing Form Inner Bottom -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Save & Close -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isAddEnabled"
                      :loading="loading"
                      @click="saveMarketingElement"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>

              </div>
              <!-- /Save & Close -->
            </el-col>
            <!-- /Left Side -->

            <!-- Center -->
            <el-col :md="12" :xl="7">
              <template v-if="!$store.state.idea.storyMode.forecast.marketingBudget.marketingElements.length">
                <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                  <p class="add-dialog-folder-text font-outfit-regular">
                    {{ $t('createdItemsWillAppearHere') }}
                  </p>
                </div>
              </template>

              <!-- Marketing Elements Added -->
              <div ref="cardHolder" class="marketing-budget-added">
                <div
                  v-for="item in $store.state.idea.storyMode.forecast.marketingBudget.marketingElements"
                  :key="item.id" class="marketing-budget-card"
                  :class="item.category"
                >
                  <set-up-and-projections-card
                    class="mb-2"
                    :entity="item"
                    :name="item.name"
                    :date="marketingElementDate(item)"
                    :amount="marketingElementAmount(item)"
                    :frequency="item.intervalType === 'one_time' ? $t('pages.businessGuide.oneTime') : $t('pages.businessGuide.monthlyAverage')"
                    :type="`${item.categoryGroup}_marketing_budget`"
                    view="list"
                    :delete-disabled="form.id === item.id"
                    :edit-disabled="form.id === item.id"
                    @edit="onEditMarketingElement"
                    @copy="onCopyMarketingElement"
                    @delete="onDeleteMarketingElement"
                  />
                  <!--                    :copy-disabled="item.category === item.categoryGroup"-->
                </div>
                <erase-icon-button
                  v-if="removeButtonVisible"
                  confirm
                  :loading="removeLoadingExamples"
                  @confirm="deleteExamples"
                />
              </div>
              <!-- /Marketing Elements Added -->

            </el-col>
            <!-- /Center -->

            <!-- Right Side -->
            <el-col :xl="10">
              <!-- Info Guide -->
              <info-guide-add-dialog
                class="mb-3 d-none d-xl-block"
                :visible="guideVisible"
                :title="$t('pages.businessGuide.marketingBudget.addDialog.guide.title')"
                :text="$t('pages.businessGuide.marketingBudget.addDialog.guide.text')"
                @click="onToggleDialogGuide"
              />
              <!-- Info Guide -->

              <!-- Ai Buddy -->
              <ai-buddy
                type="marketing-costs"
                class="ai-buddy-margin-bottom d-none d-xl-block"
                @suggestion="form.name = $event"
              />
              <!-- Ai Buddy -->
            </el-col>
            <!-- /Right Side -->
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Marketing Budget -->
</template>

<script>
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AddMarketingBudgetDatepicker from '@/views/Home/StoryMode/Forecast/MarketingBudget/Components/AddMarketingBudgetDatepicker'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDate from '@/mixins/date'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinMarketingBudget from '@/mixins/marketingBudget'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogAddMarketingBudget',

  components: {
    AiBuddy,
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogHeader,
    AddMarketingBudgetDatepicker,
    FormSwitcher,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCurrencies,
    MixinDate,
    MixinMarketingBudget,
    MixinCloseMenu,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles
  ],

  props: {
    marketingElementAction: {
      type: Object,
      default: null
    },
    copyAction: {
      type: Function,
      default: function () {}
    },
    editAction: {
      type: Function,
      default: function () {}
    },
    deleteAction: {
      type: Function,
      default: function () {}
    },
    dialogAddMarketingElementVisible: Boolean,
    onEditMarketingElement: {
      type: Function,
      default: function () {}
    },
    onCopyMarketingElement: {
      type: Function,
      default: function () {}
    },
    onDeleteMarketingElement: {
      type: Function,
      default: function () {}
    }
  },

  data () {
    const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
    const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        marketingElementCategoryLabel: '',
        category: '',
        categoryGroup: '',
        name: '',
        amount: null,
        amountIntervalType: 'one_time',
        revenueStreamPercentage: null,
        revenueStreamId: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom,
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        revenueStarts: '',
        revenueEnds: '',
        budgetChangeAmount: null,
        budgetChangePrefix: '+',
        isBudgetChangePercentage: '$',
        budgetChangeIntervalType: 'monthly'
      },
      focus: {},
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      },
      signValue: 'el-icon-plus',
      paymentOption: '$'
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    revenueStreams () {
      return this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams
    },

    isAddEnabled () {
      let enabled = this.form.name && this.form.amount && this.form.whenWillItOccur && this.form.category

      if (this.form.amountIntervalType === 'constant') {
        enabled = this.form.amount && this.form.name && this.form.starts && this.form.ends && this.form.category
      }

      if (this.form.amountIntervalType === 'revenue') {
        enabled = this.form.name && this.form.revenueStreamId && this.form.revenueStreamPercentage && this.form.starts && this.form.ends && this.form.category
      }

      return enabled
    },

    isDialogOpen () {
      return this.dialogAddMarketingElementVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getMarketingElements')
    }
  },

  watch: {
    marketingElementAction: function (element) {
      if (element !== null) {
        this.fillForm()
      }
    },

    'form.amountIntervalType': function () {
      this.setMarketingPeriod()
    },

    'form.revenueStreamId': function () {
      this.setMarketingPeriod()
    }
  },

  methods: {
    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    setMarketingPeriod () {
      if (this.form.revenueStreamId && this.form.amountIntervalType === 'revenue') {
        const revenueStreamObject = this.getRevenueStream(this.form.revenueStreamId)

        this.form.revenueStarts = this.$moment(revenueStreamObject.starts).format('YYYY-MM-DD')
        this.form.revenueEnds = this.$moment(revenueStreamObject.ends).format('YYYY-MM-DD')
      }
    },

    isCategoryDisabled (categoryName) {
      let disabled = false
      const marketingElementsCopy = [...this.$store.state.idea.storyMode.forecast.marketingBudget.marketingElements]

      for (let i = 0; i < marketingElementsCopy.length; i++) {
        disabled = marketingElementsCopy[i].category === categoryName || this.getMarketingElementParentCategory(marketingElementsCopy[i].category).value === categoryName

        if (disabled === true) {
          break
        }
      }

      return disabled
    },

    isSubcategoryDisabled (categoryName) {
      let disabled = false
      const marketingElementsCopy = [...this.$store.state.idea.storyMode.forecast.marketingBudget.marketingElements]

      for (let i = 0; i < marketingElementsCopy.length; i++) {
        disabled = marketingElementsCopy[i].category === categoryName

        if (disabled === true) {
          break
        }
      }

      return disabled
    },

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-marketing-element')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addMarketingBudgetGuide')
    },

    fillForm () {
      if (this.marketingElementAction) {
        const marketingElementAction = this.marketingElementAction

        const filterMarketingElementAction = Object.keys(marketingElementAction).reduce(function (acc, key) {
          if (marketingElementAction[key] !== null) acc[key] = marketingElementAction[key]

          return acc
        }, {})

        this.form = Object.assign(this.form, filterMarketingElementAction)

        this.form.marketingElementCategoryLabel = this.form.category
        const isParentCategory = this.marketingCategories.find(category => category.value === this.form.category)
        if (isParentCategory) {
          this.form.marketingElementCategoryLabel = isParentCategory.label
        }

        if (this.form.budgetChangePrefix === '-') {
          this.signSwitch = false
          this.toggleValue()
        }

        if (this.form.isBudgetChangePercentage === '%') {
          this.paymentSwitch = false
          this.togglePayment()
        }

        // eslint-disable-next-line array-callback-return
        Object.keys(this.form).map(key => {
          if (this.form[key]) {
            this.toggleFocus(key)
          }
        })
      }
    },

    resetForm () {
      this.focus = {}
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        marketingElementCategoryLabel: '',
        category: '',
        name: '',
        amount: null,
        amountIntervalType: 'one_time',
        revenueStreamPercentage: null,
        revenueStreamId: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom,
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        budgetChangeAmount: null,
        budgetChangePrefix: '+',
        isBudgetChangePercentage: '$',
        budgetChangeIntervalType: 'monthly'
      }

      this.signSwitch = true
      this.paymentSwitch = true
      this.togglePayment()
      this.toggleValue()
    },

    resetGuide () {
      if (this.$store.getters['idea/getMarketingElements'].length === 0) {
        this.guideVisible = false
      }
    },

    toggleValue () {
      this.signSwitch = !this.signSwitch
      if (!this.signSwitch) {
        this.signValue = 'el-icon-plus'
        this.form.budgetChangePrefix = '+'
      } else {
        this.signValue = 'el-icon-minus'
        this.form.budgetChangePrefix = '-'
      }
    },

    togglePayment () {
      this.paymentSwitch = !this.paymentSwitch
      if (!this.paymentSwitch) {
        this.paymentOption = this.form.isBudgetChangePercentage = '$'
      } else {
        this.paymentOption = this.form.isBudgetChangePercentage = '%'
      }
    },

    toggleFocus (input) {
      if (typeof this.focus[input] === 'undefined') {
        this.$set(this.focus, input)
      }

      this.focus[input] = !this.focus[input]
      if (this.form[input] !== null && this.form[input] !== '') this.focus[input] = true
    },

    selectOption (value) {
      this.form.category = value
      this.form.categoryGroup = ''

      for (let i = 0; i < this.marketingCategories.length; i++) {
        if (this.form.categoryGroup) {
          break
        }

        for (let j = 0; j < this.marketingCategories[i].children.length; j++) {
          if (value === this.marketingCategories[i].children[j].value) {
            this.form.categoryGroup = this.marketingCategories[i].value
            break
          }
        }
      }
    },

    selectGroup (group) {
      // if (this.isCategoryDisabled(group.value) === false) {
      this.form.marketingElementCategoryLabel = group.label
      this.form.category = group.value
      this.form.categoryGroup = group.value
      this.$refs.groupSelect.blur()
      // }
    },

    toggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    formatTooltip (val) {
      return val / 100
    },

    saveMarketingElement () {
      this.loading = true

      if (this.form.amountIntervalType === 'revenue') {
        this.form.starts = this.form.revenueStarts
        this.form.ends = this.form.revenueEnds
      }

      this.$http.post(`story-mode/forecast/marketing/marketing-element${this.form.id ? `/${this.form.id}` : ''}`, this.form)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            this.$emit('on-update-marketing-budget-view')
            if (this.form.id) {
              this.$store.commit('idea/updateMarketingElement', response.data.payload.marketingElement)
              this.resetForm()

              return
            }

            this.resetGuide()
            this.$store.commit('idea/addMarketingElement', response.data.payload.marketingElement)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    marketingElementDate (marketingElement) {
      if (marketingElement.whenWillItOccur) {
        return this.getFormattedDate(marketingElement.whenWillItOccur)
      }

      return `${this.getFormattedDate(marketingElement.starts)} - ${this.getFormattedDate(marketingElement.ends)}`
    },

    marketingElementAmount (marketingElement) {
      if (marketingElement.average && marketingElement.amountIntervalType !== 'one_time') {
        return this.getFormattedAmount(marketingElement.average)
      }

      return this.getFormattedAmount(marketingElement.amount)
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('marketing-elements')
        this.$emit('on-update-marketing-budget-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
